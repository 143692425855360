import { CatchGame } from "./game";

export interface IFallingObject {
  sprite: string;
  doCatch: boolean;
  horizontalSpeed: string;
  verticalSpeed: string;
  startRotation: string;
  scale: string;
  collisionScale: string;
  index: number;
  points: number;
  circle: boolean;
  changeOfSpawning: number;
  rotationSpeed: string;
  zIndex: string;
  loseLife: boolean;
}

export class FallingObject2 extends Phaser.Physics.Arcade.Image {
  config: IFallingObject;
  withinReach: boolean;
  game: CatchGame;
  invisible: boolean = false;
  rotationSpeed: number;

  particleEmitter?: Phaser.GameObjects.Particles.ParticleEmitter;

  constructor(config: IFallingObject, scene: CatchGame) {
    super(scene, 0, 0, ``);
    this.game = scene;
    this.scene.physics.add.existing(this);
    this.scene.add.existing(this);
    this.setCollideWorldBounds(true);
    this.setBounce(1);
    this.body.onWorldBounds = true;
    this.reset(config);
  }

  getValue(v: string) {
    const split = v.split("-");
    if (split.length === 1) {
      return +split[0];
    }

    const min = +split[0];
    const max = +split[1];

    return Math.random() * (max - min) + min;
  }

  reset(config: IFallingObject) {
    if (this.withinReach) {
      return;
    }
    if (this.game.colliders.includes(this)) {
      this.game.colliders.splice(this.game.colliders.indexOf(this), 1);
    }

    this.config = config;
    this.withinReach = true;
    this.invisible = false;

    this.rotationSpeed = this.getValue(config.rotationSpeed);

    this.setTexture(config.sprite);

    const aspectScale = CatchGame.getAspectScale();
    const scale = this.getValue(config.scale);

    this.setScale(aspectScale.x * scale, aspectScale.y * scale);

    const collisionScale = this.getValue(config.collisionScale);

    if (collisionScale === 0) {
      this.setBodySize(0, 0);
      this.invisible = true;
    } else {
      this.game.colliders.push(this);
      console.log(this.game.colliders);
      if (config.circle) {
        this.setCircle((this.width / 2) * collisionScale);
        const offsetX = (this.width * (1 - collisionScale)) / 2;
        const offsetY = (this.height * (1 - collisionScale)) / 2;
        this.setOffset(offsetX, offsetY);
      } else {
        this.setBodySize(
          this.width * collisionScale,
          this.height * collisionScale
        );
      }
    }

    const velocityX =
      (Math.random() > 0.5 ? 1 : -1) *
      this.getValue(config.horizontalSpeed) *
      this.game.speed *
      aspectScale.x;
    const velocityY =
      this.getValue(config.verticalSpeed) * this.game.speed * aspectScale.x;

    this.setVelocity(velocityX, velocityY);

    let rotation = 0;
    rotation =
      Math.random() *
      this.getValue(this.config.startRotation) *
      (Math.random() > 0.5 ? 1 : -1);
    this.setAngle(rotation);

    this.setRandomPosition(0, -this.displayHeight, CatchGame.width, 0);

    this.particleEmitter?.stop();

    this.particleEmitter = this.game.particles[config.sprite].createEmitter({
      scale: this.scale,
      follow: this,
      alpha: {
        start: CatchGame.config.particleStartAlpha,
        end: 0,
      },
      rotate: rotation,
      frequency: 100 / CatchGame.config.particleFrequency,
    });

    this.setDepth(this.getValue(config.zIndex));

    this.particleEmitter?.start();
    this.game.playSound("spawn");
  }

  kill() {
    this.invisible = true;
    this.withinReach = false;
    this.particleEmitter?.stop();

    this.setVelocity(0);
    this.setY(-1000);
  }

  start() {}

  update(): void {
    // this.rotation += 10;
    if (this.rotationSpeed != 0) {
      let direction = 0;
      if (this.body.velocity.x === 0) {
        direction = this.angle > 180 ? 1 : -1;
      } else {
        direction = this.body.velocity.x > 0 ? 1 : -1;
      }

      const angle =
        this.angle + this.rotationSpeed * direction * this.game.speed;
      this.setAngle(angle);
    }

    if (this.y < CatchGame.height + this.displayHeight || !this.withinReach) {
      return;
    }

    this.withinReach = false;

    if (this.invisible) {
      this.game.resetFallingObject(this);
      return;
    }

    if (this.config.doCatch) {
      if (this.config.loseLife) {
        this.game.playSound("lostLife");
        this.game.loseLife(false);
        // if (CatchGame.config.freezeOnMistake) {
        // 	this.game.pause();
        // 	setTimeout(() => {
        // 		this.game.resetFallingObject(this);
        // 		this.game.resume();
        // 	}, CatchGame.config.freezeTime);
        // 	return;
        // }
      }

      this.game.resetFallingObject(this);
      this.game.resume();

      return;
    }
    if (
      CatchGame.config.scoreType === "points" ||
      CatchGame.config.scoreType === "timeandpoints"
    ) {
      this.game.updateScore(this.config.points, true);
    }
    this.game.playSound("avoided");
    this.game.resetFallingObject(this);
  }

  destroy(fromScene?: boolean): void {
    super.destroy();
    this.particleEmitter?.stop();
  }

  pause() {
    this.particleEmitter?.pause();
  }

  resume() {
    this.particleEmitter?.resume();
  }
}
