import { IFallingObject } from "./falling-object2";

export interface GameConfig {
  path: string;

  corsFixed: boolean;
  useSVG: boolean;
  debug: boolean;

  //Splash screen
  splashSprite?: string;
  splashY: string;
  splashScale: number;
  splashOpacity: number;

  //Sprites
  catcherSprite: string;
  catcherY: number;
  catcherScale: number;
  catcherCollisionScaleX: number;
  catcherCollisionScaleY: number;
  catcherMaxRotation: number;
  catcherBounceDown: number;
  catcherBounceDownTime: number;
  catcherBounceScale: number;
  catcherBounceColor?: string;

  catcherFlip: boolean;
  catcherZ: number;

  doCatchSprite?: string;
  doCatchScale: number;
  minDoCatchScale?: number;
  maxDoCatchScale?: number;

  doCatchCollisionScale: number;

  loseLifeOnMissDoCatch: boolean;

  doNotCatchSprite?: string;
  doNotCatchScale?: number;
  minDoNotCatchScale?: number;
  maxDoNotCatchScale?: number;
  doNotCatchCollisionScale?: number;

  heartSprite: string | null;
  heartScale: number;

  // Gameplay
  spawnDoNotCatch: boolean;
  spawnDoCatch: boolean;

  spawnDelayMin: number;
  spawnDelayMax: number;

  bonusPerMs: number;

  catcherHeight: number;

  catchPoints: number;
  doNoCatchPoints: number;

  speedIncrease: number;
  speedIncreaseInterval: number;
  scoreType: "time" | "points" | "timeandpoints";
  lives: number;
  bounce: boolean;

  minVelocityHorizontal: number;
  maxVelocityHorizontal: number;

  minVelocityVertical: number;
  maxVelocityVertical: number;

  minFallingObjects: number;
  maxFallingObjects: number;
  objectIncreaseInterval: number;

  particleStartAlpha: number;
  particleFrequency: number;

  freezeOnMistake: boolean;
  freezeTime: number;
  flickerAmount: number;

  font: string;
  fontColor: string;
  fontSize: number;
  fontOffsetY: number;

  lostLifeSound: string;
  caughtSound: string;
  avoidedSound: string;
  bounceSound: string;
  spawnSound: string;
  wallBounceSound: string;

  gameStartSound: string;

  objects: IFallingObject[];
}

export const _defaultConfig: GameConfig = {
  path: "https://mediadev.playsome.nl/games/bounce/messi/",

  corsFixed: true,
  useSVG: false,
  debug: false,

  //Sprites
  catcherSprite: "catch3r.png",
  catcherY: 10,
  catcherScale: 1,
  catcherCollisionScaleX: 1,
  catcherCollisionScaleY: 1,
  catcherMaxRotation: 30,
  catcherBounceDown: 10,
  catcherBounceDownTime: 500,
  catcherFlip: true,
  catcherZ: 1000,

  spawnDelayMin: 0,
  spawnDelayMax: 0,

  splashScale: 1,
  splashY: "50",
  splashOpacity: 100,

  doCatchScale: 1,
  doCatchCollisionScale: 1,
  heartSprite: "heart.png",
  heartScale: 1,

  // Gameplay
  spawnDoNotCatch: true,
  spawnDoCatch: true,
  catchPoints: 1000,
  doNoCatchPoints: 1000,
  speedIncrease: 0.05,
  speedIncreaseInterval: 1000,
  scoreType: "timeandpoints",
  lives: 3,
  bounce: true,

  loseLifeOnMissDoCatch: true,

  catcherHeight: 0.75,

  bonusPerMs: 1,

  minVelocityHorizontal: 10,
  maxVelocityHorizontal: 100,
  minVelocityVertical: 140,
  maxVelocityVertical: 160,

  catcherBounceScale: 1,

  minFallingObjects: 3,
  maxFallingObjects: 5,
  objectIncreaseInterval: 10000,

  particleStartAlpha: 0.1,
  particleFrequency: 10,

  flickerAmount: 10,
  freezeTime: 500,
  freezeOnMistake: true,

  font: "Source Sans Pro",
  fontColor: "#000000",
  fontSize: 30,
  fontOffsetY: 0,

  lostLifeSound: "",
  caughtSound: "",
  avoidedSound: "",
  bounceSound: "",
  wallBounceSound: "",
  spawnSound: "",

  gameStartSound: "",

  objects: [],
};

export const fonts: string[] = [
  "RocknRoll One",
  "Lobster",
  "Architects Daughter",
  "UnifrakturMagnutia",
  "Pacifio",
  "Indie Flower",
  "Abril Fatface",
  "Acme",
  "Permanent Marker",
  "Amatic SC",
  "Alfa Slab One",
  "Luckiest Guy",
  "Press Start 2P",
  "Special Elite",
  "Voltaire",
  "Bungee",
  "Black Ops One",
  "Bubblegun Sans",
  "Rammetto One",
  "Wendy One",
  "Freckle Face",
  "Bungee Shade",
  "Henny Penny",
  "Barrio",
  "Flavors",
  "Bonbon",
  "Fredoka One",
  "Source Sans Pro",
];

export function printConfig(config: any) {
  let string = "";
  for (const [key, value] of Object.entries(config)) {
    string += key + "=" + value + "\n";
  }
}
