// Message to the platform
export type EventTypes =
	| "start"
	| "gameover"
	| "ready"
	| "sound"
	| "preload-sounds"
	| "score"
	| "lives"
	;

// hai

export interface GameOverData {
	score: number;
}
export type EventData = GameOverData | string | string[] | number;
export interface GameEvent {
	eventName: EventTypes;
	data?: EventData;
}

// Messages from the platform
export type PlatformEventTypes = "play" | "restart";
export interface PlatFormEvent {
	eventName: PlatformEventTypes;
}

export interface Game {
	play: any;
	restart: any;
}

export class Platform {
	private game: Game | undefined = undefined;
	constructor() {
		window.addEventListener("message", (ev: MessageEvent) => {
			this.receive(ev.data);
		});

		(<any>window).pew = this;
	}

	private receive(data: PlatFormEvent) {
		if (!this.game) {
			console.error("Error: Game not set");
			return;
		}
		switch (data.eventName) {
			case "play":
				this.game.play(); // Send play message to the game
				break;
			case "restart":
				this.game.restart();
		}
	}

	public init(game: Game) {
		this.game = game;

		this.ready();
	}

	/**
	 * Retrieve play event when game actualy starts
	 */
	public gamestarted() {
		this.sendEvent({ eventName: "start" });
	}

	public play() {
		this.game?.play();
	}

	/**
	 * Retrieve gameover event from game whith endsoce
	 */
	public gameover(score: number) {
		this.sendEvent({ eventName: "gameover", data: { score: Math.round(score) } });
	}

	/**
	 * Receive ready event from game when allowed to use start
	 */
	public ready() {
		this.sendEvent({ eventName: "ready" });
	}

	public playSound(sound: string) {
		this.sendEvent({ eventName: "sound", data: sound });
	}

	public preloadSounds(config: any) {
		let files: string[] = [];

		for (const [key, v] of Object.entries(config)) {
			if (!v) {
				continue;
			}
			const value = config.path + "/" + v;
			if (!files.includes(value) && key.includes("Sound")) {
				files.push(value);
			}
		}
		this.sendEvent({
			eventName: "preload-sounds",
			data: files,
		});
	}

	scoreFreeze: boolean = false;
	scoreFreezeTimeout: NodeJS.Timeout | null = null;

	public sendScore(score: number, withAnim: boolean = false){
		if(withAnim){
			this.sendEvent({eventName: "score", data: {score: Math.round(score)}})
			return;
		}
		this.sendEvent({eventName: "score", data: Math.round(score)})
	  }
	
	  public sendLives(lives: number){
		if(lives >= 0)
		this.sendEvent({eventName: "lives", data: lives})
	  }

	/**
	 * Send message to the game platform
	 * @param event
	 */
	private sendEvent(event: GameEvent) {
		window?.parent?.postMessage(event, "*");
	}
}
